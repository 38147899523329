// App.js
import React from 'react';
import HomePage from "./HomePage";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import PrivacyPage from "./PrivacyPage";
import AboutPage from "./AboutPage"
import BlogPage from "./BlogPage"
import Footer from "./Footer"
import Navbar from "./Navbar"
import ContactUsPage from "./ContactUsPage";
import NotFoundPage from "./NotFoundPage";
import ProductsPage from "./ProductsPage";
import ServicesPage from "./ServicesPage";
import AIIntegrationPage from "./AIIntegrationPage";
import BlockchainAIPage from "./BlockchainAIPage";
import BlockchainIntegrationPage from "./BlockchainIntegrationPage";
import FloPage from "./FloPage";
import CaseyPage from "./CaseyPage";
import CarnakPage from "./CarnakPage";
import RileyPage from "./RileyPage";
import HowBlockchainWorksPage from "./HowBlockchainWorks";
import DynamicBlogPost from "./DynamicBlogPost";

function App() {
    return (
       <Router>
           <div className={"App"}>
               <Navbar />
               <Routes>
                   <Route path={"/"} element={<HomePage />} />
                   <Route path={"about-us"} element={<AboutPage />} />
                   <Route path={"products"} element={<ProductsPage/>} />
                   <Route path={"flo"} element={<FloPage/>} />
                   <Route path={"casey"} element={<CaseyPage/>} />
                   <Route path={"carnak"} element={<CarnakPage/>} />
                   <Route path={"riley"} element={<RileyPage/>} />
                   <Route path={"blockchain-paas"} element={"BlockchainPaaSPage"} />
                   <Route path={"services"} element={<ServicesPage />} />
                   <Route path={"artificial-intelligence-integration"} element={<AIIntegrationPage />} />
                   <Route path={"blockchain-integration"} element={<BlockchainIntegrationPage />} />
                   <Route path={"blockchain-and-ai"} element={<BlockchainAIPage />} />
                   <Route path={"what-is-blockchain"} element={<HowBlockchainWorksPage />} />
                   <Route path={"privacy-policy"} element={<PrivacyPage />} />
                   <Route path={"contact-us"} element={<ContactUsPage />} />
                   <Route path={"404.html"} element={<NotFoundPage />} />
                   <Route path="/blog/*" element={<BlogPage />} />
                   <Route path="/blog/:id" element={<DynamicBlogPost />} />
                   <Route path="/blog/post/:urlkey" element={<DynamicBlogPost />} />



               </Routes>
           </div>
           <Footer />
       </Router>
    );
}

export default App;
