// HeroSection.js
import React from 'react';
import Logo from './img/BTA_icon_only.png';
import Slide from './img/services_slide5.png';
const BlockchainAIPage = () => (
    <div className="content">
        <img src={Logo} className="serviceslogo" alt={"AI Blockchain Trademarked Logo"} />
        <h1>Blockchain and AI</h1>
        <div><p>AI and Blockchain are in Kilroy Blockchain's DNA; you can see it in the block-encased "ai" in Kilroy Blockchain's trademarked logos. We can help you to create trackable, traceable AI models through applying the methodologies and framework in our book, <a href={"https://blockchaintetheredai.com"}>Blockchain Tethered AI</a> (O'Reilly, 2023). </p>
            <img className={"centeredImage"} src={Slide} alt={"AI generated scene similar to Northwest Arkansas with a beautiful lake surrounded by mountains"}/>
            <div>Fast-forward to the year 2035. How many organizational stakeholders will wish they had better documented their artificial intelligence systems? Did they create appropriate backdoors for their own programmers to gain access? Or does the system have the ability to lock out the very people who created it and drift away from the original intent? You can avoid this by planning now.</div>
           <h2>How We Can Help</h2>
            <p>Blazing a trail–a trackable, traceable one–is possible with AI and blockchain integration. Why go anywhere else? Kilroy Blockchain literally wrote the book on how to secure AI using blockchain. Let our experts relieve your burden in:</p>
            <p><ul>
                <li>Implementing C2PA and Content Authenticity Initiative standards into your AI/Blockchain deployment</li>
                <li>Understanding the four controls of blockchain tethered AI and how they apply to your application</li>
                <li>Identifying participants, transactions and assets involved in an AI training and deployment workflow</li>
                <li>Holding a design thinking session where participants identify blockchain touchpoints for your MLOps</li>
                <li>Integrating the blockchain touchpoints identified by the group into an actual machine learning workflow</li>
                <li>Helping the project stakeholders to declare and record the AI system's intent to avoid drift</li>
                <li>Integrating the blockchain tethered AI workflow with your other mission-critical systems</li>
                <li>Delivering of a proof-of-concept, prototype or minimum viable product</li>
                <li>Bringing the prototype to production</li>
                <li>Integrating customer and stakeholder reviews and ratings into your AI engineering process </li>
                <li>Deploying consumer-facing trust logos that are backed up by a tamper-evident blockchain audit trail</li>
            </ul></p>
            <p>Creating a clear, tamper-evident audit trail for your AI applications using enterprise grade blockchain is simply a great idea. Don't hesitate and wish you had acted; let our professionals help you implement a secure, private blockchain to make your AI trackable, traceable, and accountable–making you look great to your customers and giving you the competitive advantage.</p>
        </div>
    </div>
);


export default BlockchainAIPage;