// HeroSection.js
import React from 'react';
import CarnakLogo from "./img/CARNAK_logo-200.png";
import CarnakSlide from "./img/CARNAK_Guardians_of_Traffic_1024x576_original_photo_by_Karen_Kilroy.png";
const CarnakPage = () => (
    <div className="content">
        <img src={CarnakLogo} className={"pagelogo"} alt={"CARNAK Logo"} />
        <h1>CARNAK Traffic Guardian</h1>
        <h2>Engineering workflow + blockchain security</h2>
        <div><p>CARNAK is a cloud-based collaborative roadway asset management system that helps agencies to track and verify roadway assets on blockchain while building authoritative data for connected and autonomous vehicles (CV/AVs). CARNAK allows agencies to track roadway assets important to connected and autonomous vehicles such as stop signs, speed limits, school crossings, with special treatment for construction zones.</p>
            <img className={"centeredImage"} src={CarnakSlide} alt={"FLO paper airplanes"}/>
            <div>Guardians of Traffic sculpture, featuring Greek god Mercury holding a car. Hope Memorial Bridge, Cleveland, Ohio, built in 1932. Photo: Karen Kilroy</div>
            <p>CARNAK allows roadway assets to be locked down on blockchain and sold as an “official” cross-check feed to autonomous vehicles. It is our vision that this can become integrated with the agencies’ workflow process and that revenue generated by the sale of this data can be used to offset the antiquated Gasoline Excise Tax, which is currently the main source of funding for all US road construction.</p>
            <h2>How CARNAK Makes Roadways Safer</h2>

                <p>The number one detriment to vehicle safety is the distracted driver. A common conclusion among safety officials is that removing the driver through use of autonomous vehicles could eliminate most accidents.</p>

                <p>But how safe are autonomous vehicles? Current models depend on non-authoritative sources of navigation provided by commercial entities. Real-time sensing such as lidar and special roadway elements like striping are expensive and will prevent many communities from being able to support the vehicles and can fail due to something as common as a rainstorm. Crowd-dependent commercial applications such as Waze and Google supply some infrastructure data and vehicle-to-vehicle communication supplies use patterns. But how do agencies establish and maintain their own data as a baseline authority for the roadway?</p>

                <p>Roadway maintenance also plays a big factor in safety. However, the federal excise tax on gasoline, which pays for at least 80% of all US roadway maintenance, is shrinking as hybrid and electric vehicle technology advances. Through on-board payment capabilities, connected and autonomous vehicles (CV/AVs) can pay for the infrastructure data they consume, as they consume it. Since most of these advanced vehicles will be owned by commercial entities rather than individuals, real-time sale of infrastructure data can be a significant revenue source for agencies and can potentially replace the gasoline excise tax.</p>

                <p>Our system provides a blockchain network to bridge the gap among local, state and federal highway authorities and the AV networks through making verification and consumption of asset data part of roadway asset management. Participation is tokenized and revenue from data sales to third parties is distributed among agencies accordingly.</p>
            <h2>CARNAK Addresses a Broader Social Need</h2>
                <p>Blockchain technology is a useful tool for smart city but - to date - it is not yet widely used.  This should change as inherent to the blockchain technology is ability to collect and manage a permanent record of actions taken by multiple agencies from various levels of government in real time. The Carnak application focused, as it is, on traffic safety provides a compelling use case that is also a meaningful entry point for cities to grasp how blockchain technology can transform municipal operations.
                </p>
            <h2>How did we come up with CARNAK?</h2>
            <p>To invent CARNAK, we followed the LEAN methodologies espoused by Steve Blank, and we traveled across the nation to 6 states on a $25K grant from the National Science Foundation. After interviewing more than 150 traffic engineers, city innovation officers, city planners, state and federal department of transportation officials, university civil engineering directors, and CV/AV experts, we made several pivots in CARNAK to focus on modification of traffic. We found there is an overwhelming agreement among engineers that autonomous vehicles, if done right, will solve many traffic problems. However, there are not any practical solutions for implementation this infrastructure. CARNAK is designed to answer that need.</p>
                <p>Contact us today at info@kilroyblockchain.com to learn more about implementing CARNAK.</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/_8aD6AMV9kw?si=wqe2G96mzKMzWfEg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
    </div>
);

export default CarnakPage;