// AboutPage.js
import React from 'react';
import AboutSlide from './img/About_Kilroy_Blockchain-heartfelt.png';
import KarenKilroyPhoto from './img/karen_kilroy_by_daphne_youree_2018-full_length-200.png';
import FileBabyLogo from './img/file.baby@knowbots.org-file_baby_logo_square-full_trans.png';

const AboutPage = () => (
    <div className="content">
        <h1>About Us</h1>
        <p>Kilroy Blockchain is an award-winning provider of artificial intelligence and blockchain products and services based in the thriving tech community of Northwest Arkanas, home of the headquarters of the world's largest retailer.</p>
        <h2>Our Work is Heartfelt</h2><p>We are part of a global technology community, so we have exposure to what works and what doesn't, early on. Sharing our knowledge and experiences with our customers helps them to avoid unexpected pitfalls that come with new technology. </p>
<div><img src={AboutSlide} className={"centeredImage"} alt={"Beautiful AI Generated art of two hands making a heart symbol in front of a connected world"}/>
</div><h2>Our Mission</h2>
        <p>Kilroy Blockchain's mission is to use our skills, knowledge and experience to leave the world a better place than it was when we found it.</p>
            <h2>Our Values and Ethics</h2>
            <p>Our values and ethics are transparent and are detailed in our book, <a href={"https://blockchaintetheredai.com"}>Blockchain Tethered AI</a>.</p>


        <p>We appreciate our communities and we always give back through volunteerism. This includes mentoring high school students on Responsible AI and participating in standards settings bodies through volunteering for AI research lab <a href={"https://friendsofjustin.knowbots.org"}>Friends of Justin</a> and for <a href={"https://aidausergroup.org"}>AIDA User Group</a>.</p>
    <h2>Our Origin</h2>
        <p>Kilroy Blockchain began in 2016 shortly after founder Karen Kilroy attended the <a href={"https://www.gpj.com/case-study/the-ibm-cognitive-studio-at-sxsw/"}>IBM Watson Cognitive Studio AI Exhibit</a> at the SXSW tech event in Austin, Texas. After that event, Karen attended a Hyperledger Fabric meetup and decided to found Kilroy Blockchain.</p>
        <p>Karen chose to use her last name as the company's name because of the association between it and the legendary "Kilroy Was Here" meme from the 1940s that included a funny little man peeking over a fence. While there are many legends as to how this meme began, <a href={"https://www.usni.org/magazines/naval-history-magazine/1989/january/kilroy-was-here"}>the most popular version</a> involves James Kilroy, who inspected the hulls of submaries and left the Kilroy meme as his indelible mark. Because our technology is also tamper-evident, it made sense to name the company Kilroy Blockchain.</p>
    <h2>Our Founder</h2>
        <p><img src={KarenKilroyPhoto} className={"leftImage"} alt={"Karen Kilroy at IBM THINK 2018"} />Karen Kilroy is a life-long technologist with heart. A five-time <a href={"https://community.ibm.com/community/user/champions/blogs/libby-ingrassia/2024/01/18/congratulations-and-welcome-to-the-ibm-champions-c"}>IBM Champion</a>, she began programming computers at the age of 12 on a <a href={"https://d1yx3ys82bpsa0.cloudfront.net/brochures/radioshack.trs80.1977.102646108.pdf"}>Radio Shack TRS-80</a>, and has since led many development teams. Karen still does hands-on full-stack coding and system administration today, focusing on applications that do good in the world and help others.</p>
        <p>Karen's career led her from office automation to help desk and network support; to enterprise email administration and workflow systems; to ecommerce web development, digital art, videography and online marketing. This led to web application development based on blockchain and AI.
            </p>
        <p>Karen makes her knowledge available to others through her books and reports, published under the popular <a href={"https://www.oreilly.com/people/karen-kilroy/"}>O'Reilly</a> brand. Besides <a href={"https://learning.oreilly.com/library/view/blockchain-tethered-ai/9781098130541/"}>Blockchain Tethered AI</a> (2023), they include <a href={"https://learning.oreilly.com/library/view/natural-language-and/9781098156268/"}>Natural Language and Search</a> (2024), <a href={"https://learning.oreilly.com/library/view/ai-and-the/9781492091837/"}>AI and the Law</a> (2021) and <a href={"https://learning.oreilly.com/library/view/blockchain-as-a/9781492073475/"}>Blockchain-as-a-Service</a> (2019). Karen has also compiled an <a href={"https://learning.oreilly.com/playlists/b961e90f-1fdd-4e3b-b7d8-a5ba197951b2"}>Expert Playlist on AI</a> and written a blog for O'Reilly, <a href={"https://www.oreilly.com/radar/ais-opaque-box-is-actually-a-supply-chain/"}>AI's Opaque Box is Actually a Supply Chain</a>. She can frequently be found speaking at events, both local and global, online and in person.</p>

        <p><a href={"https://file.baby"}><img src={FileBabyLogo} className="rightImage" alt={"File Baby: That's My File, Baby!"}/></a>Currently, Karen chairs the <a href={"https://www.linkedin.com/showcase/hyperledger-media-entertainment-sig/"}>Hyperledger Fabric Media and Entertainment Special Interest Group's</a> Blockchain-AI Roundtable, where content provenance topics relevant to the motion picture, television, and music industries are the focus. As Co-Chair of the Coalition for <a href={"https://c2pa.org"}>Content Provenance and Authenticity (C2PA)</a> Trust List Working Group, Karen helps to set standards for implementation and tooling for use by the Content Authenticity Initiative, and has input on the associated identity standards as a member of the Creator Assertions Working Group. The effort is led by Microsoft, Adobe, BBC, New York Times, Google, OpenAI, Publis Group, Digimarc, camera makers Leica and Nikon, and many other critical organizations.
            Her passion is to end poverty by enabling all people to realize their self-worth through being paid in advance for their stories, artwork, recipies, photographs, and any other creative content that is consumed by AI models. In addition to being CEO of Kilroy Blockchain, Karen is CEO of <a href={"https://file.baby"}>File Baby</a>, where she has built tools that anyone can use to implement this vision.</p>
        <p>Karen is also a professional dragon boat coach.</p> <p>For the latest updates, make sure to connect with Karen on <a href={"https://linkedin.com/in/karenkilroy"}>LinkedIn</a>.</p>
    </div>
);


export default AboutPage;