// HeroSection.js
import React from 'react';
import RileyLogo from "./img/RILEY_logo-200.png";
import RileySlide from "./img/RILEY-home.png";
import RileyAwards from "./img/riley_awards.png"
const RileyPage = () => (
    <div className="content">
        <img src={RileyLogo} className={"pagelogo"} alt={"RILEY Logo"} />
        <h1>RILEY AI Sighted Assistant</h1>
        <h2>Trustworthy AI + blockchain security</h2>
        <p>Riley (RealLife Adventure) is Kilroy Blockchain's award-winning artificial intelligence app that provides verbose descriptions, designed to help someone to better understand their surroundings.</p>
            <img className={"centeredImage"} src={RileySlide} alt={"RILEY Real Life Adventure Home Screen"}/>
            <div>A view of RILEY's home screen, with large easy to use buttons and voice command, lets the participant choose to identify a single object or multiple things in a room, and hear the description in verbose audio.</div>
                <p>RILEY uses a smartphone or tablet to allow anyone to tap into the powerful cognitive intelligence of IBM Watson. This opens up a whole new world of independence for workers who can do a job they could not do a job before – made possible through the magic of Riley and Watson.
                </p>
        <p>While Riley was designed with people in mind who are blind and visually impaired, Riley can also help transcend language and education barriers. You won’t know what Riley can do in your workplace until it gets into your own hands. You may envision your own uses for Riley that no one else has imagined.</p>
        <h2>A Sighted Assistant for the New Collar Workforce
            Inspired by a dragon boat team that is blind and visually impaired</h2>
        <p>What’s That? uses visual recognition to recognize and describe objects and scenes...

            Train What’s That to know how to classify parts and products specific to your industry.
            Look Around provides a verbose description of the user’s surroundings...

            Train Look Around to describe your organization’s common and work areas.</p>
        <p><img src={RileyAwards} className={"centeredImageFull"} alt={"RILEY Awards: IBM Watson Build 2017 and Fast Company 2018"} /></p>
        <p>RILEY was selected as the winning application for North America in IBM’s prestegious Watson Build Challenge, 2017 and for Fast Company's World-Changing Ideas in 2018.</p>
       </div>

);

export default RileyPage;