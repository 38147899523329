// Footer.js
import React from 'react';
import CAIKBC from './img/CAI-KBC.png';
const Footer = () => (
    <section className="footer">
        <div className={"footer"}>
            <div><img src={CAIKBC} className={"centeredImage"} alt={"Content Authenticity Initiative and Kilroy Blockchain logos"}/></div>
            <p>Kilroy Blockchain is a member of the <a href={"https://contentauthenticity.org"}>Content Authenticity Initiative</a>.</p>
            <p>
                &copy;Copyright 2024, Kilroy Blockchain, All Rights Reserved
            </p>

        </div>
    </section>
);

export default Footer;


