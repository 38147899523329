// HeroSection.js
import React from 'react';
import Hero from './img/bta_slide_crack_open-3000.png';
import AILogo from './img/trademarked_kilroyblockchain_ai_logo-200.png';


const HeroSection1 = () => (
    <div>
        <div><a href="https://blockchaintetheredai.com"><img src={Hero} className={"hero-img"} alt="Crack open some knowledge with Blockchain Tethered AI by Karen Kilroy, Lynn Riley, et al" /></a></div>
        <div className={"contentauth"}><img src={AILogo} className="ailogo2" alt={"Kilroy Blockchain trademarked AI Logo"}/>
            <h1 className={"contentauth"}>Content Authenticity and Provenance for Responsible AI</h1></div>
        <p className={"home-text"}>Kilroy Blockchain, a global leader in responsible AI implementation since 2016, won North American Champion title for IBM Watson Build 2017 for our artificial intelligence app <a href={"./riley"} >RILEY</a>. We wrote the book on <a href="https://blockchaintetheredai.com">trackable, traceable artificial intelligence</a>, are an authorized blockchain provider for <a href={"https://file.baby"}>File Baby</a> and a member of the <a href={"https://contentauthenticity.org"}>Content Authenticity Initiative</a>.</p>
        <div className={"parallax"}></div>
        <div className={"homepage-text odd"}>Integrating practical custom-built web software-as-a-service applications with enterprise-grade <a href={"https://www.hyperledger.org/projects/fabric"}>Hyperledger Fabric</a> blockchain and AI is our expertise. </div>
          <div className={"parallax"}></div>
            <div className ={"homepage-text even"}>
                <h1 className={"learn-blockchain"}>Learn About Blockchain</h1>
                <p className={"understandblockchain"}>Understand the basics of blockchain and why it is used in the enterprise.</p>
                <p><a className={"whatisblockchain"} href="/what-is-blockchain">What is Blockchain?</a></p>

            </div>
        <div className={"parallax"}></div>

    </div>

);


export default HeroSection1;



