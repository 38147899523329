// HeroSection.js
import React from 'react';
import FloLogo from './img/flo_logo-300-trans-1.jpg';
import FloSlide from './img/FLO-slide2.jpg';
const FloPage = () => (
    <div className="content">
        <img src={FloLogo} className={"pagelogo"} alt={"FLO Logo"} />
        <h1>FLO Forms Workflow</h1>
        <h2>Forms workflow + blockchain security</h2>
            <p>FLO helps organizations to route office forms for approval, while leaving a blockchain audit trail. Examples include:  Leave/Time Off Application
                Expense Authorization and Expense Reimbursement
                Procurement Approval
                Customized Forms - You Tell Us.</p>
                <img className={"centeredImage"} src={FloSlide} alt={"FLO paper airplanes"}/>
                <h2>FLO keeps things moving</h2>
                <p>As an administrator, you have enough things to keep track of without relying on paper forms and the associated headaches. Now you can have all of your miscellaneous forms in one easy-to-use cloud-based interface: FLO. You can modify your team's workflows and permissions with a point-and-click interface, making sure your forms get the approvals they need, and get quickly into the hands of the person doing the processing.</p>
                <p>The added layer of blockchain helps the system to be more secure because it provides a tamper-evident audit trail. This means that if any bad or sloppy actors impact your data integrity, FLO lets you know. FLO can integrate with other systems like a purchase order or human resources system.</p><p>Contact us today at info@kilroyblockchain.com to learn more about subscribing to FLO.</p>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/__Zc8Vf088c?si=0-6Hs9J0ROH3cK0U" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

    </div>
);

export default FloPage;