import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Logo from "./img/kilroyblockchain-logo.jpg"

const Navbar = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [dropdownActive, setDropdownActive] = useState(false);
    let navigate = useNavigate();

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };
    const toggleDropdown = () => {
        setDropdownActive(!dropdownActive);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setMenuActive(false);
    };

    return (
        <section className="navbar">
            <div className="menu-icon" onClick={toggleMenu}>
                {/* Hamburger Icon */}
                {menuActive ? (
                    <span>X</span> /* Style this div as an 'X' icon */
                ) : (
                    <div>
                        <div></div>
                        <div></div>

                    </div> /* Hamburger lines */
                )}
            </div>
            <div className={`menu ${menuActive ? 'active' : ''}`}>
                <ul className={`nav-list ${menuActive ? 'active' : ''}`}>
                    <li><a href="/" onClick={() => handleNavigation('/')}>
<img src={Logo} className="logo" alt={"Kilroy Blockchain"} /></a></li>
                    <li className={"nav-item"}><a href="/" onClick={() => handleNavigation('/')}>
                        Home</a></li>
                    <li className={"nav-item"}><Link to="about-us" onClick={() => handleNavigation('/')}>About Us</Link></li>
                    <li className="nav-item dropdown">
                        <button className="dropbtn" onClick={toggleDropdown}>
                            Products
                            {/* Include an icon or character for the twisty */}
                            <span className={`twisty ${dropdownActive ? 'open' : 'closed'}`}>▼</span>
                        </button>
                        {dropdownActive && (
                            <div className="dropdown-content">
                                <Link to={"flo"} onClick={() => handleNavigation('/')}>FLO</Link>
                                <Link to="casey" onClick={() => handleNavigation('/')}>CASEY</Link>
                                <Link to="carnak" onClick={() => handleNavigation('/')}>CARNAK</Link>
                                <Link to="riley" onClick={() => handleNavigation('/')}>RILEY</Link>

                            </div>
                        )}
                    </li>
                    <li className="nav-item dropdown">
                        <button className="dropbtn" onClick={toggleDropdown}>
                            Services
                            {/* Include an icon or character for the twisty */}
                            <span className={`twisty ${dropdownActive ? 'open' : 'closed'}`}>▼</span>
                        </button>
                        {dropdownActive && (
                            <div className="dropdown-content">
                                <Link to={"artificial-intelligence-integration"} onClick={() => handleNavigation('/')}>Artificial Intelligence</Link>
                                <Link to="blockchain-integration" onClick={() => handleNavigation('/')}>Blockchain</Link>
                                <Link to="blockchain-and-ai" onClick={() => handleNavigation('/')}>Blockchain + AI</Link>

                            </div>
                        )}
                    </li>
                    <li className={"nav-item"}><Link to="blog" onClick={() => handleNavigation('/')}>Blog</Link></li>
                    <li className={"nav-item"}><Link to="contact-us" onClick={() => handleNavigation('/')}>Contact Us</Link></li>
                </ul>
            </div>
        </section>
    );
};

export default Navbar;
