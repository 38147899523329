// HeroSection.js
import React from 'react';
import CaseyLogo from "./img/Casey_logo-height-86.png"
import CaseySlide from "./img/CASEY-slide-1920.png"

const CaseyPage = () => (
    <div className="content">
        <img src={CaseyLogo} className={"pagelogo"} alt={"CASEY Logo"} />
        <h1>CASEY for Behavior Interventions</h1>
        <h2>Case management + blockchain security</h2>
        <div><p>Concerned teachers, counselors, administrators and parents form behavior intervention groups.
            Intervention groups can set action items and follow-ups and record meeting minutes.
            The intervention plan includes initial report, accommodations, expectations, determination of how outcomes will be measured, and an outcome report.</p>

            <img src={CaseySlide} className={"centeredImage"} alt={"Casey Intervention is love"} />


            <div>Preventing Crisis with CASEY: Often, teachers, counselors, administrators and parents notice a problem starting for a student early on, while there is still time to stop it. CASEY is a student behavior intervention system–designed by educators–that can be used to step in to keep a student's problems from growing.
            </div>

                <h2>Helping Intervention Teams</h2>
                <p>CASEY helps the people who care the most about the students to quickly form teams to discuss interventions privately and securely. The intervention teams, including teachers, counselors, administrators, parents and supporting professionals, can set action items, follow-up dates, schedule meetings, record meeting minutes, and use CASEY to make the intervention plan. The intervention plan guides team members through creating an initial report, accommodations, expectations, determination of how outcomes will be measured, and an outcome report.</p>

               <h2>Helping Students</h2>
                <p>CASEY helps keep students who need help the most on the radar, and gives teachers and counselors a way to take action on concerning behaviors. Outside professionals such as psychologists and authorities can also log in so they stay in the loop. CASEY reduces paperwork and stops the hassle of passing folders around from person to person and building to building.</p>

                <h2>Helping Auditors</h2>
                <p>Because CASEY uses blockchain to create a tamper-evident audit trail, the system could never undergo hacking without immediate detection. This blockchain layer of security helps to provide solid proof of the steps taken and identity of the people involved in interventions, and helps auditors because it keeps the system transparent.</p>

                <h2>Helping your Budget</h2>
                <p>CASEY integrates with your other systems so school staff does not have to type any student data, and CASEY can be used to generate reports for state officials and others. With CASEY, the focus of educators can stay on nurturing the students and intervening to keep everyone on a positive and productive track.</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/E_fwKu8dnlk?si=vobFQ4yh0FPkbshp" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

        </div>
    </div>
);

export default CaseyPage;