// HeroSection.js
import React from 'react';
import Logo from "./img/trademarked_kilroyblockchain_ai_logo-200.png";
import Slide from "./img/services_slide3.png";

const AIIntegrationPage = () => (
    <div className="content">
        <img src={Logo} className="serviceslogo" alt={"AI Blockchain Trademarked Logo"} />
        <h1>AI Integration</h1>
        <div><p>You have a world of critical choices to make when creating your AI application. Kilroy Blockchain can help you cut through the myriad of cloud hosts, AI models, and machine learning techniques–helping you to choose the best solution for your needs.</p>
            <img className={"centeredImage"} src={Slide} alt={"AI generated scene similar to Northwest Arkansas with a beautiful lake surrounded by mountains"}/>
        <div>Large Language Models and Mini Language Models in the form of AI agents, assistants, CoPilots, and GPTs will dominate the landscape of modern business. Because of this, corporate power users will change the face of how business is done–from the bottom up.</div>
            <h2>How We Can Help</h2>
            <p>Here is how we can help you with your AI application development:</p>
            <p><ul>
                <li>Developing requirements documentation for your AI model and your application
                </li>
                <li>Helping to discover which models are available and how to evaluate them
                </li>
                <li>
                    Establishing a methodology of AI model cost estimation
                </li>
                <li>
                    Setting up analytics for your models
                </li>
                <li>Working with APIs to integrate your AI application with your other applications</li>
                <li>Creating a design and implementing it in code for a working prototype</li>
                <li>Bringing the prototype to production</li>
            </ul></p>
            <p>You can count on us–Kilroy Blockchain is a globally recognized leader in AI since 2017, when we won the North American Champion title in the global IBM Watson Build competition.</p>
        </div>
    </div>
);


export default AIIntegrationPage;