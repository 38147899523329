//blogPosts/blogPosts.js
import image1 from '../img/file.baby@knowbots.org-file_baby_logo_square-full_trans.png';
import image2 from '../img/ai_bill_of_rights-blog.png';
import image3 from '../img/sxsw-transformational_blockchain_thumb300.png';
import image4 from '../img/burglar.png'
import image5 from '../img/next-gen-blockchain-networks.png'
import image6 from '../img/solution-looking-for-problem-300.png'
import image7 from '../img/ecommerce-blockchain-300.png'
import image8 from '../img/Karen_Kilroy-David_Bowie-original_photo_by_ Tony Mascia_framed.png'


const blogPosts = [
    {
        id: 1,
        urlkey:'filebaby',
        title: 'File Baby Partners with Kilroy Blockchain to Add Enterprise Security',
        author: 'Karen Kilroy',
        date: 'February 28, 2024',
        image: image1,
        alt: 'That\'s My File, BABY!',
        description: 'File Baby is partnering with Kilroy Blockchain to offer enterprise-level blockchain security to its Content Authenticity and Provenance Platform.',
        component: () => import('./BlogPost1'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 2,
        urlkey: 'ai-bill-of-rights',
        title: 'AI Bill of Rights',
        author: 'Lynn Riley',
        date: 'October 10, 2022',
        image: image2,
        alt: 'AI bill of rights',
        description: 'This week, the White House published the Blueprint for an AI Bill of Rights. To directly quote the document, the blueprint “is a set of five principles and associated practices to help guide the design, use, and deployment of automated systems to protect the rights of the American public in the age of artificial intelligence.',
        component: () => import('./BlogPost2'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 3,
        urlkey: 'video-transformational-blockchain',
        title: 'Transformational Blockchain: SXSW 2021 Interview with Todd Little, Mary Hall and Michael Ward Jr.',
        author: 'Karen Kilroy',
        date: 'August 29, 2021',
        image: image3,
        alt: 'transformational blockchain featuring Todd LIttle, Michael Ward Jr., Mary Hall and Karen Kilroy',
        description: 'Recorded in March, 2021 for South by Southwest, this video explains why blockchain can transform society, and things to consider when creating your own transformational network.',
        component: () => import('./BlogPost3'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 4,
        urlkey: 'deter-theft-with-blockchain',
        title: 'Deter Theft with Blockchain',
        author: 'Karen Kilroy',
        date: 'August 29, 2021',
        image: image4,
        alt:'deter theft with blockchain technology depicted by a burglar under a spotlight',
        description: 'Why encourage people to take advantage of the system by leaving your security wide open? Make a tamper-evident audit trail with blockchain to deter theft.',
        component: () => import('./BlogPost4'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 5,
        urlkey: 'next-gen-blockchain-networks-sxsw-inteview-2020-with-todd-little-and-paige-krieger',
        title: 'Next Gen Blockchain Networks: SXSW Inteview 2020 with Todd Little and Paige Krieger',
        author: 'Karen Kilroy',
        date: 'September 9, 2020',
        image: image5,
        alt: 'next generation blockchain networks',
        description: 'Why encourage people to take advantage of the system by leaving your security wide open? Make a tamper-evident audit trail with blockchain to deter theft.',
        component: () => import('./BlogPost5'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 6,
        urlkey: 'is-blockchain-technology-a-solution-looking-for-a-problem',
        title: 'Is Blockchain a Solution Looking for a Problem?',
        author: 'Karen Kilroy',
        date: 'May 13, 2018',
        image: image6,
        alt: 'is blockchain a solution looking for a problem?',
        description: 'Blockchain technology for the enterprise has been in the news a lot lately. According to new reports by PWC and Deloitte, blockchain for the enterprise is still high on corporate radar and growing.',
        component: () => import('./BlogPost6'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 7,
        urlkey: 'benefits-of-using-blockchain-in-ecommerce',
        title: 'Benefits of using Blockchain in Ecommerce',
        author: 'Karen Kilroy',
        date: 'August 27, 2018',
        image: image7,
        alt: 'Benefits of blockchain in e-commerce',
        description: 'Blockchain technology for the enterprise has been in the news a lot lately. According to new reports by PWC and Deloitte, blockchain for the enterprise is still high on corporate radar and growing.',
        component: () => import('./BlogPost7'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 8,
        urlkey: 'blockchain-78-david-bowie-me-and-art-provenance',
        title: 'Blockchain ’78 – David Bowie, Me, and Art Provenance',
        author: 'Karen Kilroy',
        date: 'June 14, 2018',
        image: image8,
        alt: 'Karen Kilroy and David Bowie',
        description: 'My painting of David Bowie that resurfaced 40 years later in his collection',
        component: () => import('./BlogPost8'), // Assuming BlogPost1.js returns a default React component
    },
];

export default blogPosts;