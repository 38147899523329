// ContactUsPage.js
import React from 'react';

const ContactUsPage = () => (
    <div className={"content"}>
        <h1>Contact Us</h1>
        <p className={"normal-text"}>We would love to hear from you! Please contact us one of the following ways:</p>
                <p><ul>
                <li>Email: info@kilroyblockchain.com</li>
                        <li><a href={"https://www.linkedin.com/company/16213438/"}>LinkedIn</a></li>
                </ul>
                </p>
            <div className={"parallax"}></div>
    </div>
);


export default ContactUsPage;