// BlockchainIntegrationPage.js
import React from 'react';
import Logo from './img/services_icon_blockchain.png';
import Slide from './img/services_slide4.png';
const BlockchainIntegrationPage = () => (
    <div className="content">
        <img src ={Logo} className="serviceslogo" alt={"Blockchain Integration Services Logo"} />
        <h1>Blockchain Integration</h1>
        <p>Blockchain technology is used to establish a tamper-evident audit trail for compliance with contracts and regulations.
            Because multiple parties can be blockchain participants, proactive auditing is possible by providing a node to everyone involved, including the regulatory authority.</p>

        <div><img src={Slide} className="centeredImage" alt={"services slide"} /></div>
        <div>In the future, government regulators could provide industry-vertical smart contracts that create proactive compliance and avoid millions of dollars in industry fines.
            Use cases, such as those involving food supply chain, logistics, automotive, and other highly regulated industries help to illustrate how blockchain technology can be applied to solve business problems.</div>
        <h2>How We Can Help</h2>
        <p>Here is how we can jumpstart your blockchain development effort:
            <ul>
            <li>Development of requirements documentation</li>
            <li>Helping discover your industry requirements for an effective blockchain network</li>
            <li>Discovery interviews and design thinking session to make sure the application is a fit</li>
                <li>Selection of blockchain platform, blockchain vendors, APIs/services</li>
                <li>Solutions infrastructure and architecture</li>
                <li>Planning of timeline and deliverables</li>
                <li>Development/integration with a secure front-end interface including mobile app</li>
                <li>Integration with backend systems</li>
                <li>Delivery of a proof-of-concept, prototype or minimum viable product</li>
                <li>Bringing the prototype to production</li>
            </ul></p>
            <p>There is no reason to guess at how to approach your blockchain system; you can rely on Kilroy Blockchain's many years of experience in enterprise blockchain to guide you through to a successful completion.</p>


    </div>
);


export default BlockchainIntegrationPage;