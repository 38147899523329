// HeroSection.js
import React from 'react';


const ProductsPage = () => (
    <div className="content">
        <h1>Products</h1>
        <p>text goes here</p>
    </div>
);


export default ProductsPage;