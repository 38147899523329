// HeroSection.js
import React from 'react';
import HowBlockchainWorks from "./img/how_does_blockchain_work.png";
const HowBlockchainWorksPage = () => (
    <div className="content">
        <h1>WHAT IS BLOCKCHAIN? HOW DOES BLOCKCHAIN WORK?</h1>
        <p>Blockchain technology is different than other pieces in a typical information technology stack, in that it serves a purpose that is not readily addressed by other systems. As a truth-proving backend for cryptocurrency, blockchain showed us that there are ways that ownership of data can be truly decentralized. With such decentralized distribution of data, the very act of building and maintaining the data can tokenized and assigned a value within the group. Smart contracts can replace paper contracts, eliminating reliance on someone remembering or looking up the terms. Instead, that burden is shifted to the systems themselves, because the actual legal agreement is built into the consensus workflow.</p>

        <p>Blockchain is an immutable, distributed ledger in which a block of data is encrypted and locked. Stored chronologically, the data is easily auditable.Each block is timestamped and linked to the subsequent blocks as transactions occur within peer-to-peer networks. The networks adhere to predetermined protocols to automate verification and compliance throughout workflow and processes.</p>

        <h2>How does blockchain work?</h2>
        <p>While every implementation is a little bit different, the diagram below provides a basic concept of how blockchain works.</p>
        <div className="imageContainer"><img className={"centeredImage"} src={HowBlockchainWorks} alt={"How Blockchain Works"} /></div>
        <div className="content"> <h1>Blockchain Integration</h1>
            <p>We help plan your blockchain use case and implement the network using proven solutions like Hyperledger Fabric, Ethereum, or 3rd party cloud, private cloud, and hybrid variations.</p>
                    <p><a href={"./blockchain-integration"}>Read more about Blockchain Integration</a></p>
    </div>
    </div>

);


export default HowBlockchainWorksPage;